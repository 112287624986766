<template>
  <div>
    <b-card v-if="needSetupPayment">
      <div class="text-danger">Please set your payment details on
        <router-link :to="{ name: 'profile'}">
          Profile
        </router-link>
        to receive payments
      </div>
    </b-card>
    <div
      id="payment_page"
      class="card"
    >
      <div class="table-responsive mb-0">
        <table
          role="table"
          class="table b-table table-striped table-hover"
        >
          <thead
            role="rowgroup"
            class=""
          >
            <tr
              role="row"
              class=""
            >
              <th
                role="columnheader"
                aria-colindex="0"
                :aria-sort="arialSort('date_from')"
                @click="sortCol('date_from')"
              >
                Period
              </th>
              <th
                role="columnheader"
                aria-colindex="2"
                :aria-sort="arialSort('amount')"
                @click="sortCol('amount')"
              >
                Amount
              </th>
              <th
                role="columnheader"
                aria-colindex="2"
                :aria-sort="arialSort('payment_method')"
                @click="sortCol('payment_method')"
              >
                Payment Details
              </th>
              <th
                role="columnheader"
                aria-colindex="3"
                :aria-sort="arialSort('status')"
                @click="sortCol('status')"
              >
                Status
              </th>
              <th
                role="columnheader"
                aria-colindex="4"
                :aria-sort="arialSort('due_date')"
                @click="sortCol('due_date')"
              >
                Due Date
              </th>
              <th>
                Actions
              </th>
            </tr>
          </thead>
          <tbody role="rowgroup">
            <template v-for="(rowTr, indextr) in items">
              <tr
                :key="indextr"
                :class="rowTr.class"
              >
                <td>
                  {{ rowTr.period_start | dotDateTimeShort }}
                  -
                  {{ rowTr.period_end | dotDateTimeShort }}
                </td>
                <td>
                  $ {{ rowTr.amount_text }}
                </td>
                <td>
                  {{ rowTr.payment_method_text }} <br>
                  <template v-for="(value, key) in rowTr.payment_details">
                    <div :key="key">
                      <span style="text-transform:capitalize;">{{ key }}</span>: <span style="text-transform:capitalize;">{{
                        value
                      }}</span>
                    </div>
                  </template>
                </td>
                <td>
                  <b-badge
                    v-if="rowTr.status === 0"
                    variant="light-primary"
                  >
                    {{ rowTr.status_text }}
                  </b-badge>
                  <b-badge
                    v-if="rowTr.status === 1"
                    variant="light-success"
                  >
                    {{ rowTr.status_text }}
                  </b-badge>
                  <b-badge
                    v-if="rowTr.status === 2"
                    variant="light-secondary"
                  >
                    {{ rowTr.status_text }}
                  </b-badge>
                </td>
                <td>
                  <span v-if="rowTr.due_date">{{ rowTr.due_date }}</span>
                </td>
                <td>
                  <b-button
                    variant="success"
                    size="sm"
                    @click="csvDownload(rowTr.id)"
                  >
                    CSV Download
                  </b-button>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <b-card-footer>
        <b-row>
          <b-col
            v-show="items.length > 0"
            cols="12"
          >
            <pagination-and-table-info
              :data="paginationData"
              @change="handleChangePage($event)"
            />
          </b-col>
          <b-col
            v-show="items.length === 0"
            cols="12"
            style="font-weight: 500; text-align: center"
          >
            No data
          </b-col>
        </b-row>
      </b-card-footer>
    </div>
  </div>
</template>

<script>
import PaginationAndTableInfo from '@ps_main/custom-components/PaginationAndTableInfo.vue'

export default {
  components: {
    PaginationAndTableInfo,
  },
  data() {
    return {
      url: '/api/payments',
      items: [],
      params: {
        page: 1,
        orderBy: 'id',
        itemPerPage: 5,
        orderDirection: 'desc',
      },
      responseData: null,
      needSetupPayment: false,
    }
  },
  computed: {
    paginationData() {
      return this.laravelPaginationPages(this.responseData)
    },
  },
  created() {
    window.Payments = this
    this.search()
  },
  mounted() {
    this.$http.get('/api/profile-settings/get').then(response => {
      console.log('response.data.profile', response.data.profile)
      this.needSetupPayment = response.data.profile.payment_details === null
    }).catch(errors => {
      this.alertError(errors.response.data.errors)
    })
  },
  methods: {
    handleChangePage(page) {
      this.params.page = page
      this.search()
    },
    sortCol(fieldName) {
      if (this.params.orderBy === fieldName) {
        this.params.orderDirection = this.params.orderDirection === 'asc' ? 'desc' : 'asc'
      } else {
        this.params.orderBy = fieldName
        this.params.orderDirection = 'asc'
      }
      this.search()
    },
    arialSort(sortFieldName) {
      if (this.params.orderBy === sortFieldName) {
        return this.params.orderDirection === 'asc'
          ? 'ascending'
          : 'descending'
      }
      return 'none'
    },
    search() {
      this.showLoading()
      this.$http
        .get(`${this.url}/get`, {
          params: {
            ...this.params,
          },
        })
        .then(response => {
          this.items = response.data.data
          this.responseData = response.data
          this.isBusy = false
        })
        .catch(errors => {
          this.alertError(errors.response.data)
          this.isBusy = false
        })
        .finally(() => {
          this.hideLoading()
        })
    },
    csvDownload(paymentId) {
      this.isBusy = true
      this.showLoading()
      this.$http
        .get(`${this.url}/export/${paymentId}`, {
          params: {
            ...this.params,
            ...this.filterValues,
            only_file_name: true,
          },
        })
        .then(response => {
          const filename = response.data

          this.$http
            .get(`${this.url}/export/${paymentId}`, {
              params: {
                ...this.params,
                ...this.filterValues,
              },
              responseType: 'blob',
            })
            .then(responseExport => {
              this.hideLoading()

              const url = window.URL.createObjectURL(new Blob([responseExport.data]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', filename)
              document.body.appendChild(link)
              link.click()

              this.isBusy = false
            })
            .catch(errors => {
              if (typeof errors.response === 'object') {
                if (typeof errors.response.data.errors === 'object') {
                  this.alertError(errors.response.data.errors)
                } else {
                  this.alertError(errors.response.data)
                }
              } else {
                this.alertError(errors.message)
              }

              this.hideLoading()
              this.isBusy = false
            })
        })
        .catch(errors => {
          if (typeof errors.response === 'object') {
            if (typeof errors.response.data.errors === 'object') {
              this.alertError(errors.response.data.errors)
            } else {
              this.alertError(errors.response.data)
            }
          } else {
            this.alertError(errors.message)
          }

          this.hideLoading()
          this.isBusy = false
        })
    },
  },
}
</script>

<style>
</style>
